
.box {
    display: flex;
    background-color: #333;
    border-radius: 20px;
    padding: 3vh;
    width: 40vw;
    height: 60vh;
    box-shadow: 2px 2px 1px  #333;
    font-size: 2.3vh;
    color: white;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  }