

.bubble {
    display: flex;
    background-color: #333;
    padding: 10px;
    border-radius: 40px; 
    align-items: center;
    width: 75vh;
    font-size: 2px;;
    font-family:'Courier New', Courier, monospace;
} 

.text {
    flex-grow: 1; 
    background-color: #333; 
    border: none; 
    color: white; 
    margin-right: 10px; 
    padding: 8px 12px; 
    border-radius: 10px; 
    outline: none; 

}

.send {
    background-color: white;
    color: #333;
    border: none; 
    padding: 8px 16px; 
    border-radius: 100px; 
    cursor: pointer; 
    outline: none;
}