
html, body {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%; 
  overflow: hidden; 
}

.App {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  gap: 8.5vh;
  background-color: whitesmoke;
  background-image: url('./Images/chile_verde_patio.png'); 
  background-size: cover;
  background-position: center; 
  background-repeat: no-repeat; 
  filter: grayscale(100%);
} 